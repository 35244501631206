// import htmx from 'htmx.org'
import Pocketbase from 'pocketbase'
// import '@sudodevnull/datastar'
import Alpine from 'alpinejs'
import immersiveTextComponent from './immersiveText'
import loginComponent from './login'
import { icons, createElement } from 'lucide'

// Add Alpine instance to window object.
window.Alpine = Alpine
/* @ts-ignore */
window.Pocketbase = Pocketbase
/* @ts-ignore */
// window.htmx = htmx

// Immersive Text
Alpine.data('immersiveTextComponent', immersiveTextComponent)

// Login
Alpine.data('loginComponent', loginComponent)

// Start Alpine.
Alpine.start()
