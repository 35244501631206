import htmx from 'htmx.org'
import rangy from 'rangy'

const immersiveTextComponent = () => {
  return {
    text: '',
    textEntered: false,
    showMenu: false,
    menuX: 0,
    menuY: 0,
    selectedText: '',
    selectedLanguage: 'es',
    translatedText: '',
    isPlaying: false,
    playbackSpeed: '1',
    flashcardOrientation: 'front',
    includeAudio: false,

    checkTextEntered() {
      this.textEntered = this.text.trim() !== ''
    },

    startOver() {
      this.text = ''
      this.textEntered = false
      this.showMenu = false
    },

    handleMouseUp(event: any) {
      const selection = rangy.getSelection()
      if (selection.isCollapsed) {
        this.showMenu = false
        return
      }

      this.expandSelectionToWords(selection)
      this.selectedText = selection.toString().trim()

      if (this.selectedText) {
        this.showMenu = true

        // Get the document view element
        const docView = event.target.closest('.document-view')
        const docViewRect = docView.getBoundingClientRect()

        // Calculate the position of the context menu
        const x = event.clientX - docViewRect.left + docView.scrollLeft
        const y = event.clientY - docViewRect.top + docView.scrollTop

        // Add a small offset to prevent the menu from appearing directly under the cursor
        const offsetX = 10
        const offsetY = 10

        // Ensure the menu doesn't go off-screen
        const menuWidth = 200 // Approximate width of the context menu
        const menuHeight = 150 // Approximate height of the context menu

        this.menuX = Math.min(x + offsetX, docViewRect.width - menuWidth)
        this.menuY = Math.min(y + offsetY, docViewRect.height - menuHeight)

        // Ensure the menu doesn't go off the left or top edge
        this.menuX = Math.max(this.menuX, 0)
        this.menuY = Math.max(this.menuY, 0)

        // Add the document view's offset to the menu position
        this.menuX += docViewRect.left
        this.menuY += docViewRect.top
      } else {
        this.showMenu = false
      }
    },

    expandSelectionToWords(selection: Selection) {
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const startNode = range.startContainer
        const endNode = range.endContainer
        const startOffset = range.startOffset
        const endOffset = range.endOffset

        // Expand start to word boundary
        if (startNode.nodeType === Node.TEXT_NODE && startNode.textContent) {
          let startPos = startOffset
          while (
            startPos > 0 &&
            !/\s/.test(startNode.textContent[startPos - 1])
          ) {
            startPos--
          }
          range.setStart(startNode, startPos)
        }

        // Expand end to word boundary
        if (endNode.nodeType === Node.TEXT_NODE && endNode.textContent) {
          let endPos = endOffset
          while (
            endPos < endNode.textContent.length &&
            !/\s/.test(endNode.textContent[endPos])
          ) {
            endPos++
          }
          range.setEnd(endNode, endPos)
        }

        selection.removeAllRanges()
        selection.addRange(range)
      }
    },

    translate() {
      this.$refs.translationInput.click()
      this.showMenu = false
      const result = htmx.find('.translation-result') as HTMLElement
      result.innerText = ''
      const dialog = htmx.find('#translationDialog') as HTMLDialogElement
      dialog.showModal()
    },

    closeTranslationDialog() {
      const dialog = htmx.find('#translationDialog') as HTMLDialogElement
      dialog.close()
    },

    listen() {
      this.$refs.audioInput.click()
      this.showMenu = false
      const dialog = htmx.find('#audioDialog') as HTMLDialogElement
      dialog.showModal()
    },

    closeAudioDialog() {
      const audioPlayer = document.getElementById(
        'audioPlayer'
      ) as HTMLAudioElement
      audioPlayer.pause()
      this.playbackSpeed = 1
      const dialog = htmx.find('#audioDialog') as HTMLDialogElement
      dialog.close()
    },

    changePlaybackSpeed() {
      const audioPlayer = document.getElementById(
        'audioPlayer'
      ) as HTMLAudioElement
      audioPlayer.playbackRate = parseFloat(this.playbackSpeed)
    },

    createFlashcard() {
      this.flashcardOrientation = 'front'
      htmx.ajax('post', '/card', {
        target: '#flashcard-back',
        swap: 'innerHTML',
        values: {
          lang: this.selectedLanguage,
          text: this.selectedText,
        },
      })
      this.showMenu = false
      const dialog = htmx.find('#flashcardDialog') as HTMLDialogElement
      dialog.showModal()
    },

    closeFlashcardDialog() {
      const flashcardBack = htmx.find('#flashcard-back') as HTMLElement
      flashcardBack.innerText = ''
      const dialog = htmx.find('#flashcardDialog') as HTMLDialogElement
      dialog.close()
    },

    swapFlashcard() {
      const front = htmx.find('#flashcard-front') as HTMLElement
      const back = htmx.find('#flashcard-back') as HTMLElement
      ;[front.innerText, back.innerText] = [back.innerText, front.innerText]
      this.flashcardOrientation =
        this.flashcardOrientation == 'front' ? 'back' : 'front'
    },

    saveFlashcard() {
      const front = htmx.find('#flashcard-front') as HTMLElement
      const back = htmx.find('#flashcard-back') as HTMLElement

      htmx
        .ajax('post', '/save-card', {
          target: '#flashcard-back',
          swap: 'outerHTML',
          values: {
            lang: this.selectedLanguage,
            front: front.innerText,
            back: back.innerText,
            orientation: this.flashcardOrientation,
          },
        })
        .then(() => {
          this.closeFlashcardDialog()
        })
    },

    getLanguageName() {
      const languages = {
        es: 'Spanish',
        fr: 'French',
        de: 'German',
        ru: 'Russian',
      }
      return languages[this.selectedLanguage]
    },
  }
}

export default immersiveTextComponent
