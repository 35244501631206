import Pocketbase from 'pocketbase'
import htmx from 'htmx.org'

const loginComponent = () => {
  return {
    pb: {},

    init() {
      const data = htmx.find('#params') as HTMLScriptElement
      const pbUrl = JSON.parse(data.textContent as string)
      this.pb = new Pocketbase(pbUrl)
    },

    async loginWithGoogle() {
      try {
        await this.pb.collection('users').authWithOAuth2({ provider: 'google' })
        if (this.pb.authStore.isValid) {
          window.location.href = '/'
        } else {
          console.error('Invalid oauth credentials')
        }
      } catch (e) {
        console.error(e)
      }
    },
  }
}

export default loginComponent
